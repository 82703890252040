<template>
    <div>
        <page-title :heading="$t('generic.lang_calendar') " :subheading="$t('generic.lang_calendar')" :icon=icon></page-title>
        <layout-wrapper>
        <Calendar></Calendar>
</layout-wrapper>
    </div>
</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import Calendar from "../../components/accounting/Calendar";
    import LayoutWrapper from '../../Layout/Components/LayoutWrapper';
    import DemoCard from '../../Layout/Components/DemoCard';

    export default {
        components: {
            PageTitle,
            Calendar,
            LayoutWrapper,
            DemoCard
        },
        data: () => ({
            icon: 'pe-7s-date icon-gradient bg-tempting-azure',
        })
    }
</script>