import { render, staticRenderFns } from "./CanteenMenuComponent.vue?vue&type=template&id=30a4b3df&scoped=true"
import script from "./CanteenMenuComponent.vue?vue&type=script&lang=js"
export * from "./CanteenMenuComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30a4b3df",
  null
  
)

export default component.exports