<template>
  <v-container fluid>
    <v-card elevation="0" outlined>
      <v-card-text>
        <div class="pa-0 px-0 ma-0">
          <div class="pa-0 px-0 ma-0">
            <v-btn
                class="mr-0"
                color="grey darken-2"
                outlined
                @click="setCurrentWeek(0);getMenu()"
            >
              {{ $t("generic.lang_thisWeek") }}
            </v-btn>
            <v-btn
                color="grey darken-2"
                fab
                icon
                text
                @click="setCurrentWeek(-1);getMenu()"
            >
              <v-icon large> mdi-chevron-left</v-icon>
            </v-btn>

            <v-btn
                color="grey darken-2"
                fab
                icon
                small
                @click="setCurrentWeek(1);getMenu()"
            >
              <v-icon large> mdi-chevron-right</v-icon>
            </v-btn>
            <strong class="font-weight-bolder">
              {{ this.firstday + " - " + this.lastday }}
            </strong>
            <v-spacer></v-spacer>
          </div>
        </div>
      </v-card-text>
      <v-divider class="pa-0 ma-0"/>
      <v-card-text
          v-if="this.$vuetify.breakpoint.mdAndUp && this.curr"
          class="pa-0"
      >
        <v-progress-linear indeterminate v-if="this.loadingItems"/>
        <v-row no-gutters>
          <v-col v-for="day in 6" :key="day" cols="12" md="2" sm="12">
            <v-card outlined tile>
              <v-card-title class="primary--text pb-2">
                {{ getTranslatedDay(day).replaceAll('.','') }}
                <v-spacer/>
                <v-icon @click="open_note(getDay(day))">note_add</v-icon>
              </v-card-title>
              <v-divider class="pa-0 ma-0"/>
              <v-card-text class="pa-2">
                <v-btn
                    block
                    class="mx-auto ma-auto"
                    color="grey"
                    height="100"
                    outlined
                    @click="open(getDay(day))"
                >
                  <v-icon
                      class="font-size-xlg"
                      style="font-size: 50px !important"
                  >
                    add
                  </v-icon>
                </v-btn>
                <template v-for="(item, j) in menuItems[getUntranslatedWeekDays[day - 1]]">
                  <v-card v-if="!checkIfExcluded(item, getFormatedDate(day))"
                      :key="j"
                      class="pl-1 ma-auto extra-card-mobile text-black pr-1 mt-2 image_size"
                      elevation="0"
                      min-width="100%"
                      outlined
                  >
                    <v-row class="pa-0 ma-0" no-gutters>
                      <v-col
                          class="pb-0 pointer text-center pt-1 ma-0 font-weight-bolder text-white"
                          cols="12"
                      >
                      <span
                          class="mobile_extras_name font-weight-light text-muted"
                      >
                        {{ item.aliasName || "Unknown" }}
                      </span>
                      </v-col>

                      <v-col class="text-center pb-0 pt-1" cols="12">
                        <v-avatar
                            color="primary"
                            size="70"
                            style="border: 1px solid white"
                        >
                          <img :src="item.imagePath"/>
                        </v-avatar>
                      </v-col>
                      <v-col
                          class="pb-0 pointer text-center pt-1 ma-0 font-weight-bolder text-white"
                          cols="12"
                      >
                        <h6
                            class="font-weight-bolder mt-0 font-size-md mx-auto primary&#45;&#45;text"
                        >
                          {{ item.itemPriceTotal }}$
                        </h6>
                      </v-col>

                      <v-col class="mx-auto text-left pa-0 px-0" cols="3">
                        <v-icon
                            @click="checkDelete(item, getFormatedDate(day))"
                            class="text-danger mx-auto"
                        >mdi-delete
                        </v-icon>
                      </v-col>

                      <v-col
                          class="pointer text-center mx-auto pt-0 pb-1"
                          cols="6"
                      >
                      <span class="mt-0 font-size-lg text-muted mx-auto">
                        {{ item.itemPriceTotal | currency }}
                      </span>
                      </v-col>
                      <v-col class="mx-auto text-right pointer pa-0" cols="3">
                        <v-icon
                            @click="setUpEdit(item, getDay(day))"
                            class="text-primary bg-grey"
                        >create
                        </v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </template>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!--
    select delete type
    -->
    <v-dialog v-model="selectDeleteType" max-width="400" scrollable>
      <v-card>
        <v-card-title class="card-header">
          {{ $t("generic.lang_deleteRecurringItems") }}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
                @click="
                deleteItem(
                  selectedItem.uuid,
                  'exclude',
                  selectedDay.getTime() / 1000
                ) & (selectDeleteType = false)
              "
            >
              <v-list-item-icon>
                <v-radio/>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("generic.lang_onlyThisArticl") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
                @click="
                deleteItem(selectedItem.uuid, 'full') &
                  (selectDeleteType = false)
              "
            >
              <v-list-item-icon>
                <v-radio/>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t("generic.lang_thisAndOthers") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- new item  -->
    <v-dialog v-model="dialog" max-width="700" persistent>
      <v-card>
        <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">{{ $t("generic.lang_newMenu") }}</v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row class="pt-4" no-gutters>
              <v-col class="pr-1 pl-1" cols="12" sm="6">
                <v-select
                    v-model="form.wareGroup"
                    :items="filteredWareGroups"
                    :rules="[(v) => !!v]"
                    dense
                    item-text="name"
                    item-value="id"
                    :label="$t('generic.lang_waregroup')"
                    outlined
                >
                </v-select>
              </v-col>

              <v-col class="pr-1 pl-1" cols="12" sm="6">
                <v-autocomplete
                    v-model="form.item"
                    :disabled="
                    form.wareGroup === null || form.wareGroup === undefined
                  "
                    :items="availableItems"
                    :label="this.$t('datatables.lang_search')"
                    :rules="[(v) => !!v]"
                    append-icon="search"
                    dense
                    item-text="name"
                    item-value="id"
                    outlined
                    return-object
                    @change="setPrice"
                >
                </v-autocomplete>
              </v-col>

              <v-col class="pr-1 pl-1" cols="12" sm="6">
                <v-text-field
                    v-model="form.alias"
                    :rules="[(v) => !!v]"
                    dense
                    :label="$t('generic.lang_alias')"
                    outlined
                />
              </v-col>

              <v-col class="pr-1 pl-1" cols="12" sm="6">
                <v-text-field
                    v-model="form.price"
                    :rules="[(v) => !!v]"
                    append-icon="euro_symbol"
                    dense
                    :label="$t('erp.lang_define_price')"
                    outlined
                    type="number"
                />
              </v-col>

              <v-col class="pr-1 pl-1" cols="12">
                <v-select
                    v-model="shortName"
                    chips
                    deletable-chips
                    dense
                    outlined
                    :items="shortNames"
                    :loading="loadingShortNames"
                    :label="$t('erp.lang_menuShortName')"
                    item-value="uuid"
                    item-text="menuShortName"
                >
                </v-select>
              </v-col>

              <v-col class="pr-1 pl-1" cols="12">
                <v-menu max-width="300" offset-y top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        :value="calendar"
                        dense
                        outlined
                        prepend-inner-icon="insert_invitation"
                        readonly
                    >
                      <!--                  <template v-slot:append>
                                          <v-dialog max-width="400px" overlay-opacity="0.9">
                                            <template v-slot:activator="{on, attrs}">
                                              <v-btn v-bind="attrs" v-on="on" icon style="position: absolute; top: -4px; right: 0">
                                                <v-icon color="primary">
                                                  edit
                                                </v-icon>
                                              </v-btn>
                                            </template>
                                            <v-card>
                                              <v-card-text>
                                                sdf
                                              </v-card-text>
                                            </v-card>
                                          </v-dialog>
                                        </template>-->
                    </v-text-field>
                  </template>
                  <v-card v-if="this.curr">
                    <v-card-text>
                      <v-list>
                        <v-list-item-group>
                          <v-list-item @click="selectDay(getUntranslatedWeekDays)">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("generic.lang_everyDay") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                              @click="selectDay(
                                getUntranslatedWeekDays[selectedDay.getDay()-1],
                                'week'
                              )
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("generic.lang_every") }}
                                <span class="primary--text">
                                  {{
                                    weekDays[
                                        selectedDay.getDay()-1
                                        ]
                                  }}
                                </span>
                                {{ $t("generic.lang_weekly") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                              @click="
                              selectDay(
                                getUntranslatedWeekDays[selectedDay.getDay()-1],
                                'month',
                                1
                              )
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("generic.lang_everyFirst") }}
                                <span class="primary--text">
                                  {{
                                    weekDays[selectedDay.getDay()-1]
                                  }}
                                </span>
                                {{ $t("generic.lang_monthly") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                              @click="
                              selectDay(
                                getUntranslatedWeekDays[selectedDay.getDay()-1],
                                'month',
                                2
                              )
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("generic.lang_everySecond") }}
                                <span class="primary--text">
                                  {{
                                    weekDays[selectedDay.getDay()-1]
                                  }}
                                </span>
                                {{ $t("generic.lang_monthly") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                              @click="
                              selectDay(
                                getUntranslatedWeekDays[selectedDay.getDay()-1],
                                'month',
                                3
                              )
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("generic.lang_everyThird") }}
                                <span class="primary--text">
                                  {{
                                    weekDays[selectedDay.getDay()-1]
                                  }}
                                </span>
                                {{ $t("generic.lang_monthly") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                              @click="
                              selectDay(
                                getUntranslatedWeekDays[selectedDay.getDay()-1],
                                'month',
                                4
                              )
                            "
                          >
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("generic.lang_everyLast") }}
                                <span class="primary--text">
                                  {{
                                    weekDays[selectedDay.getDay()-1]
                                  }}
                                </span>
                                {{ $t("generic.lang_monthly") }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="openCustomCalendar">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("generic.lang_custom") }} ...
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-col>

              <v-col class="pr-1 pl-1" cols="12">
                <v-select
                    v-model="mealTypes"
                    multiple
                    chips
                    deletable-chips
                    dense
                    outlined
                    :items="lunchTypes"
                    :loading="loadingLunchTypes"
                    :label="$t('erp.lang_mealType')"
                    item-value="uuid"
                    item-text="mealTypeName"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-footer pt-1 pb-1">
          <v-spacer/>
          <v-btn color="error" elevation="0" @click="close">
            {{ this.$t("generic.lang_cancel") }}
          </v-btn>
          <v-btn
              v-if="!edit"
              :loading="loadingItems"
              :disabled="loadingItems"
              color="primary"
              outlined
              @click="create"
          >
            {{ this.$t("generic.lang_create") }}
          </v-btn>

          <v-btn
              v-else
              :loading="loadingItems"
              :disabled="loadingItems"
              color="primary"
              outlined
              @click="create"
          >
            {{ this.$t("generic.lang_edit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add note of the dau -->
    <v-dialog
        v-if="this.selectedDay"
        v-model="note_dialog"
        max-width="400"
        scrollable
    >
      <v-card>
        <v-card-title class="card-header">
          {{ this.selectedDay.toDateString() }}
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form lazy-validation ref="note_form">
            <v-textarea
                :loading="noteLoading"
                :disabled="noteLoading"
                v-model="note.note"
                outlined
                dense
                :label="$t('generic.lang_writeNote')"
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="card-footer pt-0 pb-0 pr-2">
          <v-spacer/>
          <v-btn @click="closeNote" color="error" text>
            {{ $t("generic.lang_cancel") }}
          </v-btn>
          <v-btn :loading="noteLoading" @click="saveNote" color="primary" text>
            {{ $t("generic.lang_create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- select date range -->
    <v-dialog
        persistent
        v-model="range_dialog"
        max-width="400"
        overlay-opacity="0.9"
        scrollable
    >
      <v-card>
        <v-card-title class="card-header">
          {{ $t("generic.lang_pleaseSelectDateRange") }}
        </v-card-title>
        <v-card-text class="pa-0 mx-0">
          <v-form ref="range_form" lazy-validation>
            <v-col cols="12">
              <BaseDateInput
                  :rules="[
                  (v) => !!v,
                  (v) =>
                    (!!v && validTime) ||
                    $t('generic.lang_fromDateShouldBeLessThanToTime'),
                ]"
                  type="date"
                  dense
                  outlined
                  v-model="customCalendar.from"
                  :label="$t('generic.lang_from')"
              />
            </v-col>
            <v-col cols="12">
              <BaseDateInput
                  :rules="[
                  (v) => !!v,
                  (v) =>
                    (!!v && validTime) ||
                    $t('generic.lang_toDateShouldBeGreatThanFromTime'),
                ]"
                  type="date"
                  dense
                  outlined
                  v-model="customCalendar.to"
                  :label="$t('generic.lang_to')"
              />
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="card-footer pt-0 pb-0 pr-2">
          <v-btn
              @click="cancelDateRange"
              color="error"
              text
          >
            {{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn
              :disabled="!validTime"
              @click="saveUpDateRange"
              color="primary"
              text
          >
            {{ $t("generic.lang_save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- select calendar for item -->
    <v-dialog
        v-model="recurrence_dialog"
        max-width="400"
        overlay-opacity="0.9"
        scrollable
    >
      <v-card>
        <v-card-title class="card-header">{{ $t("generic.lang_customRecurences") }}</v-card-title>
        <v-card-text class="pa-0 mx-0">
          <v-form ref="recurrence_form" lazy-validation>
            <v-col cols="12">
              <v-row class="ma-0 pa-0">
                <v-col class="pb-0 px-1" cols="12">
                  <span>
                    {{ $t("generic.lang_repeatEach") }}
                  </span>
                </v-col>
                <v-col class="pb-0 px-1" cols="6">
                  <v-text-field
                      class="ma-0"
                      v-model="customCalendar.repeatEvery"
                      :value="1"
                      :min="1"
                      dense
                      outlined
                      prepend-inner-icon="filter_1"
                      type="number"
                  />
                </v-col>
                <v-col class="pb-0 px-1" cols="6">
                  <v-select
                      v-model="customCalendar.every"
                      value="day"
                      item-text="name"
                      item-value="value"
                      :items="everyItems"
                      dense
                      outlined
                  />
                </v-col>

                <v-col class="pb-0 px-1 pt-0" cols="12">
                  <span>
                    {{ $t("generic.lang_repeatEach") }}
                  </span>
                </v-col>

                <v-col class="pa-0 px-1" cols="12">
                  <v-chip-group
                      v-model="customCalendar.days"
                      multiple
                      color="primary"
                  >
                    <v-chip
                        :value="getUntranslatedWeekDays[index]"
                        v-for="(day, index) in weekDays"
                        :key="day"
                    >
                      {{ day.substring(0, 1) }}
                    </v-chip>
                  </v-chip-group>
                </v-col>

                <v-col class="pa-0 px-1 pt-4" cols="12">
                  <BaseDateInput
                      v-model="customCalendar.to"
                      :label="$t('generic.lang_endAt')"
                      type="date"
                      dense
                      outlined
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions class="card-footer pt-0 pb-0 pr-2">
          <v-spacer/>
          <v-btn @click="cancelCustomCalendar" color="error" text>
            {{ $t("generic.lang_cancel") }}
          </v-btn>
          <v-btn @click="confirmCustomCalendar" color="primary" text>
            {{ $t("generic.lang_next") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Calendar from "@/views/accounting/Calendar";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import moment from "moment";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "CanteenMenuComponent",
  components: {Calendar, BaseDateInput},
  watch: {
    "form.wareGroup": function () {
      this.getItemgroupItems();
    },
  },
  computed: {
    filteredWareGroups() {
      if (this.groups)
        return this.groups.filter((group) =>
            group.name.includes(this.search_group)
        );
      else return [];
    },
    validTime() {
      if (this.customCalendar.from != null && this.customCalendar.to != null) {
        return (
            this.customCalendar.to,
            moment(this.customCalendar.from).unix() <
            moment(this.customCalendar.to).unix()
        );
      }
      return true;
    },
    calendar: {
      get() {
        return (
            this.form.calendar.from +
            (this.form.calendar.to ? ' - ' + this.form.calendar.to : '') +
            (this.form.calendar.days && this.form.calendar.days.length > 0
                ? ' (' +
                this.form.calendar.days
                    .map((d) => this.weekDaysTranslated[d])
                    .join(',') +
                ')'
                : '')
        );
      },
    },
    getUntranslatedWeekDays() {
      return [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ];
    },
    everyItems(){
      return[
        {
          name:this.$t('generic.lang_day'),
          value:'day',
        },
        {
          name:this.$t('generic.lang_week'),
          value:'week',
        },
        {
          name:this.$t('generic.lang_month'),
          value:'month',
        },
      ]
    },
  },
  data() {
    return {
      range_dialog: false,
      groups: [],
      availableItems: [],
      selectDeleteType: false,
      loadingItems: false,
      loadingLunchTypes: false,
      loadingShortNames: false,
      noteLoading: false,
      search_group: "",
      selectedDay: "",
      dialog: false,
      note_dialog: false,
      selectedItem: null,
      today: "",
      firstday: "",
      lastday: "",
      recurrence_dialog: false,
      curr: null,
      menuItems: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      },
      note: {
        note: "",
        day: "", // the day
      },
      customCalendar: {
        from: null,
        to: null /*if equal null means that the calendar will never finish*/,
        days: [] /*'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'*/,
        every: "day" /*day , week , month*/,
        position:
            null /*we're using this attr only for months to indicate the position of the day => from 1 - 4 ( because each day can be repeated 4 time per month )*/,
        repeatEvery:
            1 /*we're using this attr when we want to repeat item very 2 weeks or 4 months .... SO WE HAVE 'every=week' and repeatEvery=3 that means repeat item every 3 weeks*/,
      },
      form: {
        wareGroup: null,
        item: null, // object
        price: null,
        alias: "" /*the new name to show in students portal*/,
        calendar: {
          from: null,
          to: null /*if equal null means that the calendar will never finish*/,
          days: [] /*'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'*/,
          every: "day" /*day , week , month*/,
          position:
              null /*we're using this attr only for months to indicate the position of the day => from 1 - 4 ( because each day can be repeated 4 time per month )*/,
          repeatEvery:
              1 /*we're using this attr when we want to repeat item very 2 weeks or 4 months .... SO WE HAVE 'every=week' and repeatEvery=3 that means repeat item every 3 weeks*/,
        },
      },
      weekDays: [
        this.$t("generic.Montag"),
        this.$t("generic.Dienstag"),
        this.$t("generic.Mittwoch"),
        this.$t("generic.Donnerstag"),
        this.$t("generic.Freitag"),
        this.$t("generic.Samstag"),
        this.$t('generic.Sonntag')
      ],
      weekDaysTranslated: {
        Monday: this.$t('generic.Montag'),
        Tuesday: this.$t('generic.Dienstag'),
        Wednesday: this.$t('generic.Mittwoch'),
        Thursday: this.$t('generic.Donnerstag'),
        Friday: this.$t('generic.Freitag'),
        Saturday: this.$t('generic.Samstag'),
        Sunday: this.$t('generic.Sonntag')
      },
      lunchTypes: [], //lunch types goes here
      mealTypes: [],
      shortName: null,
      shortNames: [],
      edit: false,
    };
  },
  methods: {
    checkIfExcluded(item, day) {
      if (item.hasOwnProperty('excludedDates') && item.excludedDates) {
        return item.excludedDates.includes(day);
      }
      return false;
    },
    async getItemgroupItems() {
      if (this.form.wareGroup && parseInt(this.form.wareGroup) > 0) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        let items = await this.$store.dispatch(
            "items/getItemsByItemGroupIDSortByName",
            this.form.wareGroup
        );
        if (!Array.isArray(items)) this.availableItems = [];
        else this.availableItems = [...items];
      } else return [];
    },
    async wareGroups() {
      let itemgroups = await this.$store.dispatch("itemgroups/getItemgroups");

      this.groups = itemgroups
          .map((itemgroup) => {
            return {
              id: itemgroup.id,
              name: itemgroup.name,
            };
          })
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
    },

    /**
     * convert H:m to seconds
     * */
    hmToSecondsOnly(time = "") {
      if (time != null) {
        let arr = time.split(":");
        if (Array.isArray(arr) && arr.length >= 2) {
          return parseInt(arr[0]) * 3600 + parseInt(arr[1]) * 60;
        }
      }
      return 0;
    },
    saveUpDateRange() {
      if (!this.$refs.range_form.validate()) return;
      this.form.calendar = Object.assign({}, this.customCalendar);
      this.range_dialog = false;
    },
    async setUpEdit(item, day) {
      let dt = new Date(day + ' ' + this.curr.getFullYear());
      let tmp = moment.unix(Math.floor(dt.getTime() / 1000));
      this.selectedDay = new Date(tmp.format('YYYY-MM-DD'));
      this.form.uuid = item.uuid;
      this.form.wareGroup = item.itemgroupId;

      this.form.item = await this.$store.dispatch(
          "items/getItemByID",
          item.itemId
      );
      this.form.alias = item.aliasName;
      this.form.price = parseFloat(item.itemPriceTotal);

      this.form.calendar.from = moment.unix(item.calendarFrom).format('YYYY-MM-DD');
      this.form.calendar.to = moment.unix(item.calendarTo).format('YYYY-MM-DD');
      this.form.calendar.days = item.days;
      this.form.calendar.every = item.every;
      this.form.calendar.position = item.position;
      this.form.calendar.repeatEvery = item.repeatEvery;

      this.mealTypes = item.mealTypeUUIDs;
      this.shortName = item.uuidMenuShortName;

      //  //console.log(this.form)
      //show dialog
      this.edit = true;
      this.dialog = true;
    },
    cancelDateRange() {
      if (this.$refs.range_form !== undefined)
        this.$refs.range_form.reset();
      this.range_dialog = false;
    },
    cancelCustomCalendar() {
      if (this.$refs.recurrence_form !== undefined)
        this.$refs.recurrence_form.reset();
      this.recurrence_dialog = false;
    },
    confirmCustomCalendar() {
      this.form.calendar = Object.assign({}, this.customCalendar);
      this.recurrence_dialog = false;
      this.form.calendar.position = null;
      this.$refs.recurrence_form.reset();
    },
    checkDelete(elt, day) {
      this.selectedDay = new Date(
          this.$moment(day,'DD.MM.YYYY').unix() * 1000
      );
      if (elt.days && elt.days.length >= 2) {
        this.selectedItem = elt;
        this.selectDeleteType = true;
      } else
        this.deleteItem(elt.uuid, 'full', this.selectedDay.getTime() / 1000);
    },
    deleteItem(uuid, deleteType, date = new Date().getTime() / 1000) {
      //console.log(deleteType)
      this.$swal({
        title: this.$t("erp.lang_ware_delete_confirm"),
        text: this.$t("erp.lang_ware_delete_confirm"),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios
              .post(ENDPOINTS.KANTINE.MENU.ITEMS.DELETE, {
                uuid: uuid,
                deleteType: deleteType,
                excludedDayTS: Math.floor(date),
              })
              .then((res) => {
                if (res.data.success) {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_success"),
                    color: "success",
                  });
                  this.getMenu();
                } else {
                  Events.$emit("showSnackbar", {
                    message: this.$t("generic.lang_errorOccurred"),
                    color: "error",
                  });
                }
              })
              .catch(() => {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              });
        },
      });
    },
    getMenu() {
      this.loadingItems = true;

      let startFilter = moment(this.firstday,'dddd MMMM DD YYYY').startOf('day').unix();
      let endFilter = moment(this.lastday,'dddd MMMM DD YYYY').endOf('day').unix();

      this.axios
          .post(ENDPOINTS.KANTINE.MENU.ITEMS.GET, {
            start: startFilter,
            end: endFilter,
          })
          .then((res) => {
            if (res.data.status) {
              let items = res.data.items;
              /*we clear data to avoid items duplication*/
              this.menuItems = {
                Monday: [],
                Tuesday: [],
                Wednesday: [],
                Thursday: [],
                Friday: [],
                Saturday: [],
                Sunday: [],
              };
              items.forEach((elt) => {
                let startCal = elt.calendarFrom;
                let endCal =  elt.calendarTo ? elt.calendarTo : endFilter;

                if (elt.excludedDates)
                  elt.excludedDates = JSON.parse(elt.excludedDates).map((d) => {
                    return moment.unix(d).format('DD.MM.YYYY');
                  });
                if (elt.days !== null && elt.days !== '' && elt.days.length > 0) {
                  elt.days.forEach((d) => {
                    let index = Object.keys(this.weekDaysTranslated).indexOf(d);
                    if(moment.unix(startFilter).add(index,'days').startOf('day').isSameOrAfter(moment.unix(startCal).startOf('day')) &&
                        moment.unix(startFilter).add(index,'days').isSameOrBefore(moment.unix(endCal))||
                        (
                            moment.unix(endFilter).isSameOrAfter(moment.unix(startCal)) &&
                            moment.unix(endFilter).isSameOrBefore(moment.unix(endCal)) &&
                            moment.unix(startFilter).isSameOrAfter(moment.unix(endCal)) &&
                            moment.unix(startFilter).isSameOrBefore(moment.unix(startCal))
                        )
                    ) {
                      this.menuItems[d]?.push(elt);
                    }
                  });
                }
              });
            } else {
              Events.$emit('showSnackbar', {
                message: this.$t('generic.lang_errorOccurred'),
                color: 'error',
              });
            }
          })
          .catch((err) => {
            Events.$emit('showSnackbar', {
              message: err.message,
              color: 'error',
            });
          })
          .finally(() => {
            this.loadingItems = false;
          });
    },
    getNote() {
      this.noteLoading = true;
      this.axios
          .post(ENDPOINTS.KANTINE.MENU.NOTE.GET, {
            unixTimestamp: this.selectedDay.getTime() / 1000,
            note: this.note.note,
          })
          .then((res) => {
            this.note.note = res.data.messageOfTheDay;
            this.note.uuid = res.data.uuid;
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.noteLoading = false;
          });
    },
    saveNote() {
      this.noteLoading = true;
      let dt = {
        unixTimestamp: this.selectedDay.getTime() / 1000,
        note: this.note.note,
      };

      //console.log(new Date(dt.unixTimestamp), dt.unixTimestamp)

      /*here we're checking if this note is already exist so we can update it*/
      if (
          this.note.uuid !== null &&
          this.note.uuid !== undefined &&
          this.note.uuid !== ""
      )
        dt.uuid = this.note.uuid;

      this.axios
          .post(ENDPOINTS.KANTINE.MENU.NOTE.UPDATE, dt)
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_success"),
                color: "success",
              });
              this.closeNote();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_errorOccurred"),
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.noteLoading = false;
          });
    },
    selectDay(ds, every = "day", position = null) {
      if (Array.isArray(ds)) {
        this.form.calendar.days = ds.map((d) => {
          return d;
        });
        this.form.calendar.every = every;
        this.form.calendar.position = null;
        this.form.calendar.repeatEvery = 0;
      } else {
        this.form.calendar.days = [ds];
        this.form.calendar.every = every;
        this.form.calendar.position = position;
        this.form.calendar.repeatEvery = 0;
      }
      /**
       * show date range dialog
       */
      this.openDateRange();
    },
    setPrice() {
      this.form.price = this.form.item.sellPrice;
      this.form.alias = this.form.item.name;
    },
    create() {
      if (!this.$refs.form.validate()) return;
      this.loadingItems = true;
      let fr = {
        item: this.form.item.id,
        price: this.form.price,
        alias: this.form.alias,
        from: new Date(this.form.calendar.from).getTime() / 1000,
        to: new Date(this.form.calendar.to).getTime() / 1000,
        days: this.form.calendar.days,
        every: this.form.calendar.every,
        repeatEvery: this.form.calendar.repeatEvery,
        position: this.form.calendar.position,
        mealTypeUUIDs: this.mealTypes,
        shortNameUUID: this.shortName,
      };

      if (this.edit) fr.uuid = this.form.uuid;
      this.axios
          .post(ENDPOINTS.KANTINE.MENU.ITEMS.UPDATE, fr)
          .then((res) => {
            if (res.data.status === "SUCCESS") {
              this.close();
              this.getMenu();
            } else {
              Events.$emit("showSnackbar", {
                message: res.data.msg || res.data.status,
                color: "error",
              });
            }
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error",
            });
          })
          .finally(() => {
            this.loadingItems = false;
          });
    },
    close() {
      this.$refs.form.reset();
      this.cancelCustomCalendar();
      this.form.calendar.from = moment().format("YYYY-MM-DD");
      this.form.calendar.to = moment().format("YYYY-MM-DD");
      this.dialog = false;
      this.edit = false;
    },
    closeNote() {
      this.note_dialog = false;
      this.note.note = "";
      this.note.uuid = null;
    },
    open_note(day) {
      if (Array.isArray(day.split(" ")) && day.split(" ").length >= 3) {
        this.selectedDay = new Date(
            this.curr.getFullYear() +
            "-" +
            (this.curr.getMonth() + 1) +
            "-" +
            day.split(" ")[2]
        );
        this.note.day =
            this.curr.getFullYear() +
            "-" +
            (this.curr.getMonth() + 1) +
            "-" +
            day.split(" ")[1];
        this.note_dialog = true;
        this.getNote();
      }
    },
    getCompleteDayName(day, is_string = false) {

      //get Day name by offset
      if (
          !is_string &&
          day &&
          !Number.isNaN(parseInt(day)) &&
          parseInt(day) > -1
      ) {
        return this.weekDays[day];
      } else if (is_string) {
        let index = this.weekDays.indexOf(day);
        if (index > -1) {
          return this.weekDays[index];
        }
        return this.weekDays[0]; //use monday as default day
      }
    },
    open(day) {
      let dt = new Date(day + " " + this.curr.getFullYear());
      let tmp = moment.unix(Math.floor(dt.getTime() / 1000));
      this.selectedDay = new Date(tmp.format("YYYY-MM-DD"));
      this.form.calendar.from = tmp.format("YYYY-MM-DD");
      this.form.calendar.to = tmp.format("YYYY-MM-DD");

      this.form.calendar.days = [
        this.getUntranslatedWeekDays[this.selectedDay.getDay()-1]
      ];

      this.dialog = true;
    },
    openCustomCalendar() {
      this.customCalendar = Object.assign({}, this.form.calendar);
      this.recurrence_dialog = true;
    },
    openDateRange(){
      this.customCalendar = Object.assign({}, this.form.calendar);
      this.range_dialog = true;
    },
    getTranslatedDay(i) {
      return this.$moment(this.curr.getTime())
          .startOf('isoWeek')
          .add(i - 1, 'days')
          .format('ddd MMM DD')
    },
    getDay(i) {
      /*let dt = new Date(this.curr.getTime())
      let first = dt.getDate() - dt.getDay()
      dt.setDate(first)*/
      let dt = new Date(
          moment(this.curr.getTime())
              .startOf("isoWeek")
              .add(i - 1, "days")
              .valueOf()
      );

      return (
          dt.toDateString().split(" ")[0] +
          " " +
          dt.toDateString().split(" ")[1] +
          " " +
          dt.toDateString().split(" ")[2]
      );
    },
    getFormatedDate(date) {
      return moment(this.curr.getTime())
          .startOf('isoWeek')
          .add(date - 1, 'days')
          .format('DD.MM.YYYY');
    },
    setCurrentWeek(next = 0) {
      if (this.curr === null || next == 0) {
        this.curr = new Date();
        this.selectedDay = new Date(moment().format('YYYY-MM-DD'));
      }

      //  let first = this.curr.getDate() - this.curr.getDay() + 1 // First day is the day of the month - the day of the week
      if (next > 0) {
        this.curr = new Date(
            moment(this.curr.getTime()).startOf('isoWeek').add(7, 'days').valueOf()
        );
      } else if (next < 0) {
        this.curr = new Date(
            moment(this.curr.getTime()).startOf('isoWeek').add(-7, 'days').valueOf()
        );
      }

      /* let last = first + 5
       this.firstday = new Date(this.curr.setDate(first)).toDateString()
       this.lastday = new Date(this.curr.setDate(last)).toDateString()*/
      this.firstday = this.$moment(this.curr.getTime())
          .startOf('isoWeek')
          .format('dddd MMMM DD YYYY');

      this.lastday = this.$moment(this.curr.getTime())
          .startOf('isoWeek')
          .add(5, 'days')
          .format('dddd MMMM DD YYYY');
    },
    getLunchTypes() {
      this.loadingLunchTypes = true;

      this.axios
          .post("get/canteen/mealTypes/")
          .then((res) => {
            this.lunchTypes = res.data;
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loadingLunchTypes = false;
          });
    },
    getShortNames() {
      this.loadingShortNames = true;

      this.axios
          .post(ENDPOINTS.KANTINE.SETTINGS.GLOBALSETTINGS.MENUSHORTNAME.GETALL)
          .then((res) => {
            this.shortNames = res.data;
          })
          .catch((err) => {
            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message,
            });
          })
          .finally(() => {
            this.loadingShortNames = false;
          });
    },
  },
  mounted() {
    this.setCurrentWeek();
    this.form.calendar.from = moment
        .unix(Math.floor(new Date().getTime() / 1000))
        .format('YYYY-MM-DD');
    this.form.calendar.to = moment
        .unix(Math.floor(new Date().getTime() / 1000))
        .format('YYYY-MM-DD');
    this.getLunchTypes();
    this.getShortNames();
    this.getMenu();

    this.wareGroups();
  },
};
</script>

<style scoped></style>
